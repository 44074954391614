import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { Link } from '@remix-run/react'
import classnames from 'classnames'
import { Button } from '~/components/design-system/button'
import type { ReactNode } from 'react'

type Props = {
  image: ReactNode
  title: string
  subtitle: string
  children: ReactNode
  readMoreLink: string
}

export default function Card({ image, title, subtitle, readMoreLink, children }: Props) {
  return (
    <div className="row-span-full grid grid-rows-subgrid rounded border border-brand-light p-2 shadow-strong">
      {image}

      <div className={classnames('flex h-full flex-col justify-between p-3', 'lg:p-6')}>
        <div className="flex flex-col">
          <div className={classnames('mb-1 text-xs font-bold uppercase tracking-wide text-brand-utility', 'lg:text-sm')}>{subtitle}</div>
          <h3 className={classnames('mb-2 text-base font-black', 'lg:text-xl')}>{title}</h3>
          {children}
        </div>

        <Button variant="outline" size="md" className="mt-4" asChild>
          <Link to={readMoreLink}>
            Read more
            <ArrowRightIcon className="size-4" />
          </Link>
        </Button>
      </div>
    </div>
  )
}
